/** @jsxImportSource @emotion/react */
import React from "react";
import { useMemo, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "@emotion/react";
import classNames from "classnames";
import { Alert } from "react-bootstrap";
import _ from "lodash";
import {
  useTable,
  useFilters,
  useSortBy,
  usePagination,
  useFlexLayout,
  useResizeColumns,
  useExpanded,
} from "react-table";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortDown,
  faSortUp,
} from "@fortawesome/pro-regular-svg-icons";
import Styles from "./Styles/BaseStyle";
import DefaultTheme from "./Styles/Themes/DefaultTheme";
import DarkTheme from "./Styles/Themes/DarkTheme";
import LightTheme from "./Styles/Themes/LightTheme";
import LightChildTheme from "./Styles/Themes/LightChildTheme";
import { ScrollArea } from "components/atoms/ScrollArea.atom";

// Auto scroll the table to the top of the table container
const scrollToTableTop = () => {
  document.documentElement.scrollTop =
    document.getElementsByClassName("table-container")[0].offsetTop;
};

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterInputPlaceholder, filterValue, setFilter },
}) {
  const debouncedSetFilter = _.debounce(setFilter, 333);
  return (
    <input
      placeholder={filterInputPlaceholder}
      defaultValue={filterValue ?? ""}
      onChange={(e) => {
        // Set undefined to remove the filter entirely.
        debouncedSetFilter(e.target.value ?? undefined);
      }}
    />
  );
}

DefaultColumnFilter.propTypes = {
  column: PropTypes.object,
};

export const Themes = {
  DEFAULT: DefaultTheme,
  DARK: DarkTheme,
  LIGHT: LightTheme,
  LIGHT_CHILD: LightChildTheme,
};

// This hook is to be used for `useControlledState` in useTable's options.
// Our hook takes the current table state and BaseTable's props and returns a memoized
// table state with some values controlled by props.
const useCustomControlledState = (tableState, props) => {
  // Each of these useMemo calls check if their values should be controlled
  //   (e.g. isManualPagination),
  // then return an object of values that need to be controlled in table state
  //   (e.g. pageIndex, pageSize, pageCount).
  //
  // Note: It is intended to do these in separate useMemo calls.
  // If it is done in one useMemo, we end up updating too many times and get an error.

  let pagination = useMemo(() => {
    let overrideValues = {};

    if (props.isManualPagination) {
      overrideValues.pageIndex = props.pageIndex;
      overrideValues.pageSize = props.pageSize;
      overrideValues.pageCount = props.pageCount;
    }

    return overrideValues;
  }, [
    props.isManualPagination,
    props.pageIndex,
    props.pageSize,
    props.pageCount,
  ]);

  let sortBy = useMemo(() => {
    let overrideValues = {};

    if (props.isManualSorting || props.isControlledSorting) {
      if (props.sortColumn) {
        overrideValues.sortBy = [
          { id: props.sortColumn, desc: props.reverseSort ?? false },
        ];
      } else if (props.defaultSortColumn) {
        overrideValues.sortBy = [
          { id: props.defaultSortColumn, desc: props.defaultReverseSort },
        ];
      } else {
        overrideValues.sortBy = [];
      }
    }

    return overrideValues;
  }, [
    props.isManualSorting,
    props.isControlledSorting,
    props.sortColumn,
    props.reverseSort,
    props.defaultSortColumn,
    props.defaultReverseSort,
  ]);

  let filters = useMemo(() => {
    let overrideValues = {};

    if (props.isManualFilterable) {
      overrideValues.filters = props.filters;
    }

    return overrideValues;
  }, [props.isManualFilterable, props.filters]);

  // This is an object of whatever tableState is plus all the
  // controlled values overwriting what they need.
  //
  // Note: The return needs to be memoized per react-table's docs.
  return useMemo(() => {
    return {
      ...tableState,
      ...pagination,
      ...sortBy,
      ...filters,
    };
  }, [tableState, pagination, sortBy, filters]);
};

export const BaseTable = (props) => {
  // Base props
  const {
    "data-qa": dataQa,
    isLoading,
    columns,
    data,
    theme = Themes.DEFAULT,
    hideHeader = false,
    hideBorder = false,
    fixHeaderToTop = false,
    height = null,
    rowClickHandler,
    rowHighlightHandler,
    rowDisabledHandler,
    noDataFoundMessage,
  } = props;

  // Pagination props
  const {
    disablePagination = false,
    showPagination = false,
    showPageSizeDropdown = true,
    fixPaginationToBottom = false,
    hideTotalPages = false,
    isManualPagination = false,
    onPageChange,
    onPageSizeChange,
  } = props;

  // Sorting props
  const {
    disableSortBy = false,
    isManualSorting = false, // API/UI handles sorting each column
    isControlledSorting = false, // ReactTable handles sorting, but UI handles column/reverse/onPageSort
    onPageSort,
  } = props;

  // Filter props
  const {
    showFilters = false,
    isManualFilterable = false,
    onFilterChange,
  } = props;

  // Sub-Component props
  const { subComponent } = props;

  const { t } = useTranslation();

  // Define default column properties
  const defaultColumn = useMemo(
    () => ({
      // When using the useFlexLayout:
      minWidth: 30, // minWidth is only used as a limit for resizing
      width: 150, // width is used for both the flex-basis and flex-grow
      maxWidth: 500, // maxWidth is only used as a limit for resizing
      Filter: DefaultColumnFilter,
    }),
    [],
  );

  let pageNumberInput = useRef(null);

  const {
    getTableBodyProps,
    headers,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    totalColumnsWidth,
    setSortBy,
    state: { pageIndex, pageSize, filters },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      disableSortBy: disableSortBy || !data || data.length === 0 || isLoading,
      initialState: {
        pageIndex: props.pageIndex ?? 0,
        pageSize: props.pageSize ?? 20,
        sortBy:
          // We want to init state regardless if we are controlled/manual sorting.
          props.sortColumn
            ? [{ id: props.sortColumn, desc: props.reverseSort }] // Sort by what's provided
            : props.defaultSortColumn // If we have a default sort,
            ? [{ id: props.defaultSortColumn, desc: props.defaultReverseSort }] // Sort by the default sort
            : [], // Otherwise, don't sort by default
        filters: isManualFilterable && props.filters ? props.filters : [],
        expanded: props.expanded ?? {},
      },
      // If manualPagination is true, react-table expects props.data to be
      // paginationed outside of this component. It will assume we've limited
      // the length of props.data already. It will render all of the array as rows.
      manualPagination: disablePagination || isManualPagination,
      manualSortBy: isManualSorting,
      // pageCount needs to be -1 if we don't know how many pages we have.
      // https://react-table.tanstack.com/docs/api/usePagination#table-options
      // It can't default to undefined or it may cause issues as of v7.7.0.
      // A value of undefined could be assumed the same as not passing it in but that is not the case.
      // https://github.com/tannerlinsley/react-table/issues/2321#issuecomment-647850730
      pageCount: disablePagination
        ? -1
        : isManualPagination
        ? props.pageCount
        : -1,
      disableFilters: !showFilters,
      manualFilters: isManualFilterable,
      autoResetRowState: false,
      autoResetPage: false,
      autoResetExpanded: false,
      useControlledState: (tableState) =>
        useCustomControlledState(tableState, props),
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useFlexLayout,
    useResizeColumns,
  );

  // When the page size changes, update the PageNumberInput
  useEffect(() => {
    if (pageNumberInput.current) {
      pageNumberInput.current.value = pageIndex + 1;
    }
  }, [pageSize, pageIndex]);

  // For manual pagination only.
  // Handles the edge case of the current page being above the total pages.
  // (Also makes sure we have a page count).
  // This occurs when our last page has one item and that item get's removed. Then we would be one page above the total pages.
  // H1-4109: This happens with unresolved locaitons where we can resolve a row and when we navigate back, the row is removed.
  useEffect(() => {
    if (isManualPagination && pageIndex >= pageCount && pageCount > 0) {
      let newPage = pageCount - 1;
      gotoPage(newPage);
      onPageChange(newPage);
    }
  }, [isManualPagination, gotoPage, onPageChange, pageIndex, pageCount]);

  const updatePage = () => {
    let page = pageNumberInput.current.value
      ? Number(pageNumberInput.current.value) - 1
      : 0;

    if (page <= 0) {
      pageNumberInput.current.value = 1;
      page = 0;
    }

    if (page > pageCount - 1) {
      pageNumberInput.current.value = pageCount;
      page = pageCount;
    }

    gotoPage(page);
    if (isManualPagination) {
      onPageChange(page);
    }

    scrollToTableTop();
  };

  const updatePageDebounced = _.debounce(updatePage, 600);

  return (
    <ThemeProvider theme={theme}>
      <Styles>
        <div
          className="table-container"
          css={hideBorder ? { border: 0 } : null}
          data-qa={dataQa ? "table-container-" + dataQa : null}
        >
          <div
            css={{
              height:
                fixHeaderToTop && (!page || page.length === 0)
                  ? "auto"
                  : height,
            }}
            className="table"
            role="table"
            data-qa={dataQa ? "table-" + dataQa : null}
          >
            <ScrollArea>
              {/* Header */}
              {!hideHeader ? (
                <div
                  className={classNames("thead", {
                    "header-fixed": fixHeaderToTop,
                  })}
                  data-qa={dataQa ? "thead-" + dataQa : null}
                >
                  <React.Fragment>
                    <div
                      className="tr headers"
                      data-qa={dataQa ? "thead-headers-" + dataQa : null}
                    >
                      {headers.map((column, index) => {
                        const sortByToggleProps = column.getSortByToggleProps();

                        return (
                          <div
                            {...column.getHeaderProps()}
                            className={classNames("th", {
                              isResizing: column.isResizing,
                              canResize: column.canResize,
                              canSort: column.canSort,
                              rightAligned: column.rightAligned,
                              centerAligned: column.centerAligned,
                            })}
                            data-qa={
                              dataQa
                                ? "thead-headers-th-" + index + "-" + dataQa
                                : null
                            }
                          >
                            {/* Setup sorting */}
                            {column.canSort && (
                              <div
                                {...sortByToggleProps}
                                className="header-content"
                                onClick={(sortEvents) => {
                                  // Let react-table do what it needs to do.
                                  sortByToggleProps.onClick(sortEvents);

                                  let sortColumn = column.id;
                                  let reverseSort = column.isSortedDesc;

                                  if (isManualSorting || isControlledSorting) {
                                    // For controlled/manual sorting, we can't do much else if we don't have a callback.
                                    if (onPageSort) {
                                      // If it is already sorted, we will either remove the sort or flip the direction.
                                      if (column.isSorted) {
                                        if (reverseSort) {
                                          if (props.defaultSortColumn) {
                                            // If we have a default sortColumn, we want to flip the reverse sort
                                            // when the user changes the sort order.
                                            onPageSort(
                                              props.defaultSortColumn,
                                              !reverseSort,
                                            );
                                          } else {
                                            onPageSort(null, null);
                                          }
                                        } else {
                                          onPageSort(sortColumn, !reverseSort);
                                        }
                                      }
                                      // Otherwise, we are add a sort to the column.
                                      else {
                                        onPageSort(sortColumn, false);
                                      }
                                    }
                                  }
                                  // If its not controlled/manual
                                  else {
                                    // If it is sorted and sort is already reversed, usually we would remove the sort.
                                    if (column.isSorted && reverseSort) {
                                      // But if this is the defaultSortColumn, we want to cycle between ascending and descending.
                                      if (
                                        column.id === props.defaultSortColumn
                                      ) {
                                        setSortBy([
                                          {
                                            id: sortColumn,
                                            desc: !reverseSort,
                                          },
                                        ]);
                                      }
                                      // If this isn't the defaultSortColumn, check if we have it and then set that default.
                                      else if (props.defaultSortColumn) {
                                        setSortBy([
                                          {
                                            id: props.defaultSortColumn ?? null,
                                            desc:
                                              props.defaultReverseSort ?? false,
                                          },
                                        ]);
                                      }
                                    }
                                  }
                                }}
                              >
                                {column.render("Header")}

                                {/* Sort direction indicator */}
                                <div
                                  className={classNames("sort-indicator", {
                                    isSorted: column.isSorted,
                                  })}
                                  data-qa="sort-indicator"
                                >
                                  {column.isSorted ? (
                                    column.isSortedDesc ? (
                                      <FontAwesomeIcon
                                        icon={faSortDown}
                                        alt={t("Sorted descending")}
                                      />
                                    ) : (
                                      <FontAwesomeIcon
                                        icon={faSortUp}
                                        alt={t("Sorted ascending")}
                                      />
                                    )
                                  ) : (
                                    <FontAwesomeIcon
                                      icon={faSort}
                                      alt={t("Sort this column")}
                                    />
                                  )}
                                </div>
                              </div>
                            )}

                            {/* No sorting available */}
                            {!column.canSort && (
                              <div className="header-content">
                                {column.render("Header")}
                              </div>
                            )}

                            {/* Setup column resizing */}
                            {column.canResize && (
                              <div
                                {...column.getResizerProps()}
                                className={classNames("resizer", {
                                  isResizing: column.isResizing,
                                })}
                                data-qa={
                                  dataQa
                                    ? "thead-headers-th-" +
                                      index +
                                      "-resizer-" +
                                      dataQa
                                    : null
                                }
                              />
                            )}
                          </div>
                        );
                      })}
                    </div>
                    {/* Filters */}
                    {showFilters && (
                      <div
                        className="tr filters"
                        data-qa={dataQa ? "thead-filters-" + dataQa : null}
                      >
                        {headers.map((column, index) => (
                          <div
                            {...column.getHeaderProps()}
                            className={classNames("th", {
                              isResizing: column.isResizing,
                              rightAligned: column.rightAligned,
                              centerAligned: column.centerAligned,
                            })}
                            data-qa={
                              dataQa
                                ? "thead-filters-filter-" + index + "-" + dataQa
                                : null
                            }
                          >
                            {column.canFilter && (
                              <div className="filter">
                                {column.render("Filter", {
                                  column: {
                                    ...column,
                                    setFilter: (value) => {
                                      // Call internal function to update the filter in useTable's state.
                                      column.setFilter(value);

                                      // If this is controlled, we also need to call `onFilterChange` from props with the next filters array.
                                      if (
                                        isManualFilterable &&
                                        onFilterChange
                                      ) {
                                        // Cloning the array so that when we modify it, we don't update the same object used elsewhere.
                                        let updatedFilters =
                                          _.cloneDeep(filters);

                                        // Handle the value when it is `undefined` or `null`.
                                        if (_.isNil(value)) {
                                          // Note: _.remove mutates the array.
                                          _.remove(
                                            updatedFilters,
                                            (item) => item.id === column.id,
                                          );
                                        }
                                        // Handle the non-undefined/null value
                                        else {
                                          const existingFilterIndex =
                                            _.findIndex(
                                              updatedFilters,
                                              (item) => item.id === column.id,
                                            );

                                          // An existingFilterIndex >= 0 means it exists already, so we just want to update it.
                                          if (existingFilterIndex >= 0) {
                                            updatedFilters[
                                              existingFilterIndex
                                            ].value = value;
                                          }
                                          // An existingFilterIndex of -1 means we don't have the filter set yet.
                                          else {
                                            updatedFilters.push({
                                              id: column.id,
                                              value,
                                            });
                                          }
                                        }

                                        // After updatedFilters is fully updated, we can call the callback.
                                        onFilterChange(updatedFilters);
                                      }
                                    },
                                  },
                                })}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </React.Fragment>
                </div>
              ) : null}

              {/* Body */}
              <div
                {...getTableBodyProps()}
                className={classNames("tbody", {
                  isLoading: isLoading,
                })}
                style={{ minWidth: totalColumnsWidth }}
                data-qa={dataQa ? "tbody-" + dataQa : null}
              >
                <div
                  className={classNames("loading", "stripe", {
                    "stripe-visible": isLoading,
                  })}
                  data-qa={dataQa ? "tbody-loading-" + dataQa : null}
                ></div>
                {!isLoading && (!page || page.length === 0) && (
                  <Alert
                    style={{ marginBottom: 0 }}
                    variant={"warning"}
                    data-qa={dataQa ? "tbody-nodata-" + dataQa : null}
                  >
                    {noDataFoundMessage
                      ? noDataFoundMessage
                      : t("No data found.")}
                  </Alert>
                )}
                {isLoading && (!data || data.length === 0) && (
                  <div className="loading-text">{t("reports:Loading...")}</div>
                )}
                {page.map((row, rowIndex) => {
                  prepareRow(row);

                  let isDisabled = rowDisabledHandler
                    ? rowDisabledHandler(row)
                    : false;
                  let isClickable = !_.isNil(rowClickHandler) && !isDisabled;
                  let isHighlighted =
                    rowHighlightHandler && !isDisabled
                      ? rowHighlightHandler(row)
                      : false;

                  return (
                    <React.Fragment key={rowIndex}>
                      <div
                        {...row.getRowProps()}
                        className={classNames("tr", {
                          clickable: isClickable,
                          highlighted: isHighlighted,
                          disabled: isDisabled,
                        })}
                        data-qa={
                          dataQa ? "tbody-row-" + rowIndex + "-" + dataQa : null
                        }
                      >
                        {row.cells.map((cell, cellIndex) => {
                          return (
                            <div
                              {...cell.getCellProps()}
                              className={classNames("td", {
                                isResizing: cell.column.isResizing,
                                rightAligned: cell.column.rightAligned,
                                centerAligned: cell.column.centerAligned,
                              })}
                              onClick={() => {
                                if (isClickable) {
                                  rowClickHandler(row, cell);
                                }
                              }}
                              data-qa={
                                dataQa
                                  ? "tbody-row-" +
                                    rowIndex +
                                    "-cell-" +
                                    cellIndex +
                                    "-" +
                                    dataQa
                                  : null
                              }
                            >
                              {cell.render("Cell")}
                            </div>
                          );
                        })}
                      </div>
                      {row.isExpanded && subComponent({ row })}
                    </React.Fragment>
                  );
                })}
              </div>
            </ScrollArea>
          </div>
          {/* Pagination */}
          {showPagination && (
            <div
              className={classNames("pagination-container", {
                "pagination-fixed": fixPaginationToBottom,
              })}
              data-qa={dataQa ? "pagination-" + dataQa : null}
            >
              <div className="-pagination">
                <div className="-previous">
                  <button
                    type="button"
                    disabled={!canPreviousPage || isLoading}
                    className="-btn"
                    onClick={(e) => {
                      pageNumberInput.current.value--;
                      previousPage();
                      if (isManualPagination) {
                        onPageChange(pageIndex - 1);
                      }
                      scrollToTableTop();
                    }}
                    data-qa={dataQa ? "pagination-previous-" + dataQa : null}
                  >
                    {t("Previous")}
                  </button>
                </div>
                <div className="-center">
                  <span className="-pageInfo">
                    {t("Page") + " "}
                    <div className="-pageJump">
                      <input
                        type="number"
                        disabled={
                          !data ||
                          data.length === 0 ||
                          isLoading ||
                          hideTotalPages
                        }
                        // This is defaultValue instead of value so that this element is uncontrolled.
                        // If we set this to value instead, the user wouldn't be able to type to change the page number.
                        defaultValue={pageIndex + 1}
                        ref={pageNumberInput}
                        onChange={(e) => {
                          // Ignore changes if the value is empty
                          if (pageNumberInput.current.value) {
                            // Immediatelly check to see if we are above or below the min/max
                            // and prevent going above/below them if so.
                            let page =
                              Number(pageNumberInput.current.value) - 1;

                            if (page <= 0) {
                              pageNumberInput.current.value = 1;
                            }

                            if (page > pageCount - 1) {
                              pageNumberInput.current.value = pageCount;
                            }

                            // Call a debounced call to updatePage so that we can allow the
                            // user to type in their preferred page number, then after X ms
                            // we will update the page.
                            updatePageDebounced();
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.keyCode === 13) {
                            updatePageDebounced();
                          }
                        }}
                        onBlur={(e) => {
                          updatePageDebounced();
                        }}
                        data-qa={
                          dataQa ? "pagination-currentpage-" + dataQa : null
                        }
                      />
                    </div>{" "}
                    {!hideTotalPages ? (
                      <span>
                        {t("of")}{" "}
                        <span
                          className="-totalPages"
                          data-qa={
                            dataQa ? "pagination-totalpages-" + dataQa : null
                          }
                        >
                          {pageCount}
                        </span>
                      </span>
                    ) : null}
                  </span>
                  {showPageSizeDropdown ? (
                    <span className="-pageSizeOptions">
                      <select
                        value={pageSize}
                        disabled={!data || data.length === 0 || isLoading}
                        onChange={(e) => {
                          let newPageSize = Number(e.target.value);
                          setPageSize(newPageSize);
                          if (isManualPagination) {
                            onPageSizeChange(newPageSize);
                          }

                          scrollToTableTop();
                        }}
                        data-qa={
                          dataQa ? "pagination-pagesize-" + dataQa : null
                        }
                      >
                        {[5, 10, 20, 25, 50, 100].map((dropdownPageSize) => (
                          <option
                            key={dropdownPageSize}
                            value={dropdownPageSize}
                          >
                            {dropdownPageSize + " " + t("rows")}
                          </option>
                        ))}
                      </select>
                    </span>
                  ) : null}
                </div>
                <div className="-next">
                  <button
                    type="button"
                    className="-btn"
                    disabled={!canNextPage || isLoading}
                    onClick={(e) => {
                      pageNumberInput.current.value++;
                      nextPage();
                      if (isManualPagination) {
                        onPageChange(pageIndex + 1);
                      }
                      scrollToTableTop();
                    }}
                    data-qa={dataQa ? "pagination-next-" + dataQa : null}
                  >
                    {t("Next")}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </Styles>
    </ThemeProvider>
  );
};

export const BaseTablePropTypes = {
  "data-qa": PropTypes.string,
  isLoading: PropTypes.bool,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
        PropTypes.func,
      ]).isRequired,
      accessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      id: PropTypes.string,
      minWidth: PropTypes.number,
      width: PropTypes.number,
      maxWidth: PropTypes.number,
      disableSortBy: PropTypes.bool,
      disableResizing: PropTypes.bool,
      disableFilters: PropTypes.bool,
      centerAligned: PropTypes.bool,
      rightAligned: PropTypes.bool,
      Cell: PropTypes.elementType,
      sortType: PropTypes.func,
      Filter: PropTypes.elementType,
      filter: PropTypes.func,
      sortColumnOverride: PropTypes.string,
      /**
       * Property on column that determines if it can be expanded to
       * return additional data.  Works with TreeCells.
       */
      expandedDataCanExpand: PropTypes.string,
      /**
       * Property on column that will contain additional data to display
       * when expanding.  Works with TreeCells.
       */
      expandedDataAttribute: PropTypes.string,
      /**
       * Property on column that will contain icon value to display
       * when expanding or collapsing.  Works with TreeCells.
       */
      openIcon: PropTypes.object,
      closedIcon: PropTypes.object,
      openColor: PropTypes.string,
      closedColor: PropTypes.string,
      filterInputPlaceholder: PropTypes.string,
    }),
  ),
  data: PropTypes.array,
  theme: PropTypes.object,
  subComponent: PropTypes.func,
  hideHeader: PropTypes.bool,
  hideBorder: PropTypes.bool,
  rowClickHandler: PropTypes.func,
  rowHighlightHandler: PropTypes.func,
  rowDisabledHandler: PropTypes.func,
  noDataFoundMessage: PropTypes.string,
  showPagination: PropTypes.bool,
  showPageSizeDropdown: PropTypes.bool,
  /**
   * Fix the header to the top of the table.
   * The height prop is required when this is true.
   */
  fixHeaderToTop: PropTypes.bool,
  /**
   * The fixed height of the table.
   */
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fixPaginationToBottom: PropTypes.bool,
  isManualPagination: PropTypes.bool,
  isManualSorting: PropTypes.bool,
  isControlledSorting: PropTypes.bool,
  pageIndex: PropTypes.number,
  pageCount: PropTypes.number,
  pageSize: PropTypes.number,
  disablePagination: PropTypes.bool,
  defaultSortColumn: PropTypes.string,
  defaultReverseSort: PropTypes.bool,
  sortColumn: PropTypes.string,
  reverseSort: PropTypes.bool,
  disableSortBy: PropTypes.bool,
  onPageChange: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  onPageSort: PropTypes.func,
  hideTotalPages: PropTypes.bool,
  showFilters: PropTypes.bool,
  isManualFilterable: PropTypes.bool,
  filters: PropTypes.array,
  onFilterChange: PropTypes.func,
  expanded: PropTypes.object,
};

BaseTable.propTypes = BaseTablePropTypes;
