import moment from "moment";

import { customerApiUrl } from "api-url";
import buildSearchBarState from "components/search-bar/SearchBarStateBuilder";

import {
  INVENTORY_VIEW_DETAILS_FILTERS,
  INVENTORY_VIEW_DETAILS_SEARCH_CATEGORIES,
} from "../details/search/InventoryView.Details.Search.Options";

const STORE_MOUNT_POINT = "inventoryViewDetailsSearch";

const UPDATE_CURRENT_LOCATION = `${STORE_MOUNT_POINT}/UPDATE_CURRENT_LOCATION`;

const fetchSearch = (queryString = null, solutionId, duck, dispatch, state) => {
  const url = customerApiUrl(
    `/entity-inventory/location/${state.location.payload.locationId}/search?${queryString}`,
  );

  const config = {
    headers: {
      "x-time-zone": moment.tz.guess(),
      Accept: "application/json;",
    },
  };

  // Fetch the search
  dispatch(duck.fetch(url, config));

  dispatch({
    type: "INVENTORY_VIEW_DETAILS",
    payload: { locationId: state.location.payload.locationId },
  });
};

// Selectors
const setCurrentLocation = (id) => {
  return { type: UPDATE_CURRENT_LOCATION, payload: id };
};

const detailsSearchState = (
  state = {
    currentLocation: null,
  },
  action,
) => {
  switch (action.type) {
    case UPDATE_CURRENT_LOCATION:
      return {
        ...state,
        currentLocation: action.payload,
      };

    default:
      return state;
  }
};

const SearchBarState = buildSearchBarState(
  STORE_MOUNT_POINT,
  INVENTORY_VIEW_DETAILS_SEARCH_CATEGORIES,
  INVENTORY_VIEW_DETAILS_FILTERS,
  fetchSearch,
  [detailsSearchState],
);

SearchBarState.selectors = {
  ...SearchBarState.selectors,
};

SearchBarState.actionCreators = {
  ...SearchBarState.actionCreators,
  setCurrentLocation,
};

export default SearchBarState;
