import { customerApiUrl } from "api-url";
import {
  PaginationType,
  buildAsyncFilterOptionsState,
} from "components/search-bar/AsyncFilterOptionsStateBuilder";

const productTypesUrl = (state) => {
  const inventoryLocationId = state.location.payload.locationId;
  return customerApiUrl(
    `/entity-inventory/location/${inventoryLocationId}/search/options/product-type`,
  );
};

const destinationDealerUrl = (state) => {
  const inventoryLocationId = state.location.payload.locationId;
  return customerApiUrl(
    `/entity-inventory/location/${inventoryLocationId}/search?destinationDealer=1`,
  );
};

const currentLocationUrl = (state) => {
  const inventoryLocationId = state.location.payload.locationId;
  return customerApiUrl(
    `/entity-inventory/location/${inventoryLocationId}/search?currentLocation=1`,
  );
};

export const currentPositionCodesUrl = (state) => {
  const inventoryLocationId = state.location.payload.locationId;
  return customerApiUrl(
    `/entity-inventory/location/${inventoryLocationId}/search?currentPositionCodes=1`,
  );
};

const orderTypeUrl = (state) => {
  const inventoryLocationId = state.location.payload.locationId;
  return customerApiUrl(
    `/entity-inventory/location/${inventoryLocationId}/search/options/order-type`,
  );
};

export const productTypeOptionsState = buildAsyncFilterOptionsState({
  topic: "ivProductTypeFilter",
  getUrl: (solutionId, state) => productTypesUrl(state),
  getResponseData: (data) => data?.productTypes ?? [],
  transformResult: (option) => ({ label: option, value: option }),
  paginationType: PaginationType.OPEN_SEARCH,
});

export const destinationDealerOptionsState = buildAsyncFilterOptionsState({
  topic: "ivDestinationDealerFilter",
  getUrl: (solutionId, state) => destinationDealerUrl(state),
  getResponseData: (data) => data?.destinations ?? [],
  transformResult: (option) => ({
    label: `${option.name} (${option.code})`,
    value: option.code,
  }),
});

export const currentLocationOptionsState = buildAsyncFilterOptionsState({
  topic: "ivcurrentLocationFilter",
  getUrl: (solutionId, state) => currentLocationUrl(state),
  getResponseData: (data) => data?.currentLocations ?? [],
  transformResult: (option) => ({ label: option.name, value: option.name }),
});

export const currentPositionsCodeOptionsState = buildAsyncFilterOptionsState({
  topic: "ivCurrentPositionCodeFilter",
  getUrl: (solutionId, state) => currentPositionCodesUrl(state),
  getResponseData: (data) => data?.currentPositionCodes ?? [],
  transformResult: (result) => ({
    label: result.value,
    value: result.id,
  }),
});

export const orderTypesOptionsState = buildAsyncFilterOptionsState({
  topic: "ivOrderTypesFilter",
  getUrl: (solutionId, state) => orderTypeUrl(state),
  getResponseData: (data) => data?.orderTypes ?? [],
  transformResult: (option) => ({ label: option.name, value: option.name }),
  paginationType: PaginationType.OPEN_SEARCH,
});
