import { connect } from "react-redux";

import { getSolutionId } from "modules/organizations/OrganizationsState";
import InventoryViewInsights from "./InventoryView.Insights.page";

import InventorySearchBarState from "../redux/InventoryViewDetailsSearchBarState";
import InventoryViewDetailsState from "../redux/InventoryViewDetailsState";

function mapStateToProps(state) {
  const locationId = state.location.payload.locationId;
  const { getLocation } = InventoryViewDetailsState.selectors;

  const {
    getSearchResults,
    getIsLoading,
    getPage,
    getPageSize,
    getShowAdvancedSearch,
    getSortColumn,
    getReverseSort,
    getTotalEntities,
  } = InventorySearchBarState.selectors;

  return {
    solutionId: getSolutionId(state),
    locationId: locationId,
    location: getLocation(state),
    page: getPage(state),
    pageSize: getPageSize(state),
    sortColumn: getSortColumn(state),
    reverseSort: getReverseSort(state),
    isLoading: getIsLoading(state),
    isOnsiteVinsCountLoading: false,
    searchResults: getSearchResults(state),
    totalCount: getTotalEntities(state),
    showFilters: getShowAdvancedSearch(state),
    searchFilters: InventorySearchBarState.selectors.getSearchFilters(state),
  };
}

const {
  searchEntities,
  resetSearchAndFilters,
  toggleShowFilters,
  setCurrentLocation,
} = InventorySearchBarState.actionCreators;

const { fetchLocation } = InventoryViewDetailsState.actionCreators;

const actionCreators = {
  fetchLocation,
  setCurrentLocation,
  redirectToDetailsView:
    InventoryViewDetailsState.actionCreators.redirectToDetailsView,
  redirectToDashboardView:
    InventoryViewDetailsState.actionCreators.redirectToDashboardView,
  onsiteVinsResetSearch: (solutionId) => (dispatch) => {
    dispatch(resetSearchAndFilters());
    dispatch(searchEntities(solutionId));
  },
  toggleShowFilters: (showFilters) => (dispatch) => {
    dispatch(toggleShowFilters(showFilters));
  },
  setSearchFilter: (key, value) => (dispatch) =>
    dispatch(
      InventorySearchBarState.actionCreators.setSearchFilter(key, value),
    ),
};

export default connect(mapStateToProps, actionCreators)(InventoryViewInsights);
