/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { SearchBar } from "components/search-bar/SearchBar";
import PropTypes from "prop-types";

const SearchBarContainer = ({ setSearchText, searchText, setPageNumber }) => {
  const { t } = useTranslation("critical-parts");
  const [localSearchText, setLocalSearchText] = useState(searchText);
  const [hasSearchTextChanged, setHasSearchTextChanged] = useState(false);

  useEffect(() => {
    setLocalSearchText(searchText);
  }, [searchText]);

  useEffect(() => {
    if (localSearchText !== searchText) {
      setHasSearchTextChanged(true);
    } else {
      setHasSearchTextChanged(false);
    }
  }, [localSearchText, searchText]);

  const handleSetSearchText = (text) => {
    setLocalSearchText(text);
  };

  const clearSearchText = () => {
    setLocalSearchText("");
    setSearchText("");
  };

  const handleSearchButtonClick = () => {
    setSearchText(localSearchText);
  };

  const searchEntities = (solutionId, resetPagination) => {
    if (resetPagination) {
      setPageNumber(0);
    }
    handleSearchButtonClick();
  };

  const typeaheadOptionsMetadata = [
    {
      itemLabelProperty: "label",
      itemValueProperty: "value",
      queryKey: "exampleQueryKey",
      label: (t) => "exampleLabel", // Temp: removed t() to avoid paying for this translation
      placeholder: (t) => t("Search Part Name or Number"),
    },
  ];

  const searchCategory = typeaheadOptionsMetadata[0];

  return (
    <div data-qa="search-bar">
      <SearchBar
        hasSearchCriteriaChanged={hasSearchTextChanged}
        isShowingFilters={false}
        toggleShowFilters={() => {
          console.log("togglepressed");
        }}
        isShowingAdvancedSearchButton={true}
        preventSearchOnChange={false}
        searchCategory={searchCategory}
        setSearchCategory={() => {
          console.log("setSearchCategory");
        }}
        resetSearchBar={clearSearchText}
        searchText={localSearchText}
        canUserSearch={true}
        clearSavedSearch={() => {
          console.log("clearSavedSearch");
        }}
        setSearchText={handleSetSearchText}
        t={t}
        searchEntities={searchEntities}
        typeaheadOptionsMetadata={typeaheadOptionsMetadata}
        showSearchOptions={false}
      />
    </div>
  );
};

SearchBarContainer.propTypes = {
  setSearchText: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
};

export default SearchBarContainer;
