import _ from "lodash";

import { customerApiUrl } from "api-url";
import buildFetchDuck from "vendor/signal-utils/build-fetch-duck";
import { combineReducers } from "redux";

// Exported URLs
export const currentPositionCodesUrl = (inventoryLocationId) => {
  return customerApiUrl(
    `/entity-inventory/location/${inventoryLocationId}/search?currentPositionCodes=1`,
  );
};

const STORE_MOUNT_POINT = "inventoryViewDomainData";

const inventoryViewPositionTypesDuck = buildFetchDuck(
  "inventoryViewPositionTypesDuck",
);
const inventoryViewCarrierDuck = buildFetchDuck("inventoryViewCarrierDuck");

export function fetchDomainData(inventoryLocationId) {
  const urlPositionTypes = customerApiUrl(
    `/entity-inventory/location/${inventoryLocationId}/search?currentPositionTypes=1`,
  );

  const urlCarrier = customerApiUrl(
    `/entity-inventory/location/${inventoryLocationId}/search?carrier=1`,
  );

  return (dispatch) => {
    dispatch(inventoryViewPositionTypesDuck.fetch(urlPositionTypes));
    dispatch(inventoryViewCarrierDuck.fetch(urlCarrier));
  };
}

const createSelectorFromProperty = (property, parent, sort = false) => {
  return (state) => {
    let dataFromState = state[STORE_MOUNT_POINT][parent]?.data[property];

    if (dataFromState) {
      if (sort) {
        // Sorting
        if (_.isString(sort)) {
          return _.sortBy(dataFromState, sort);
        }

        // Check if the data is prone to sort. In this case, the only possible
        // type which comes from the backend and is not sortable is object
        if (_.isObject(dataFromState[0])) {
          throw Error(
            `Property ${property} contains objects that can not be sorted`,
          );
        }

        return dataFromState.sort();
      }
      return dataFromState;
    }
    return [];
  };
};

const getCurrentPositionTypes = createSelectorFromProperty(
  "currentPositionTypes",
  "currentPositionTypes",
);

const getCarriers = createSelectorFromProperty(
  "carriers",
  "carriers",
  "carrier_name",
);

const InventoryViewDomainData = {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchDomainData,
  },
  selectors: {
    getCurrentPositionTypes,
    getCarriers,
  },
  reducer: combineReducers({
    currentPositionTypes: inventoryViewPositionTypesDuck.reducer,
    carriers: inventoryViewCarrierDuck.reducer,
  }),
};

export default InventoryViewDomainData;
