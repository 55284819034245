/** @jsxImportSource @emotion/react */
import { CSSProperties, ReactNode } from "react";
import { useSubcomponentOverride } from "components/hooks/useSubcomponentOverride";
import { Text, FontSize, SharedTextProps } from "components/atoms/Text.atom";
import Colors from "styles/colors";
import { parseDateTime } from "utils/date-time";

/**
 * Displays the given date time from `props.dateTime`.
 *
 * For customization, you may pass in children of the specific part of this component you want to change.
 *
 * The following are valid children and any others are ignored:
 *
 * - `<DateTime.Time />`
 *
 * - `<DateTime.Timezone />`
 *
 * - `<DateTime.Date />`
 *
 * Valid props for subcomponents are the same as `<Text />` but `children` is not accepted.
 *
 * This example forces the date to always be bold.
 *
 * ```jsx
 * <DateTime>
 *   <DateTime.Date bold />
 * </DateTime>
 * ```
 */
export const DateTime = ({
  dateTime,
  fallback,
  localize,
  stack,
  plain,
  showDate = true,
  showTime = true,
  size = FontSize.size14,
  style = {},
  bold = false,
  className,
  children,
}: DateTimeProps) => {
  const { date, time, timezone, isValid, a11yTs } = parseDateTime(
    dateTime,
    localize ?? false,
  );

  // Define text sizes relative to the passed in size.
  const baseText = size * 1; // This is the same. We can change later if need be.
  const smallText = size * 0.9; // This is slightly smaller.

  // For each component below,
  // if any children are passed, allow overriding default props.

  const timeComponent = useSubcomponentOverride(
    children,
    <DateTime.Time size={baseText} time={time} bold={!plain || bold} />,
  );

  const timezoneComponent = useSubcomponentOverride(
    children,
    <DateTime.Timezone
      timezone={timezone}
      size={plain ? baseText : smallText}
      color={plain ? undefined : Colors.text.GRAY}
      bold={bold}
    />,
  );

  const dateComponent = useSubcomponentOverride(
    children,
    <DateTime.Date
      date={date}
      size={stack && !plain ? smallText : baseText}
      bold={bold}
    />,
  );

  if (!showDate && !showTime) {
    throw new Error("`showDate` and `showTime` props cannot both be false.");
  }

  if (!isValid) {
    if (!fallback) {
      console.error(
        "Received an invalid datetime string from `props.dateTime` on the DateTime component.",
      );
      return null;
    }
    return fallback;
  }

  return (
    <time
      data-qa="datetime"
      css={{
        display: stack ? "inline-block" : "inline",
      }}
      style={style}
      className={className}
      dateTime={a11yTs}
    >
      {showTime ? (
        <span css={{ display: stack ? "block" : "inline" }}>
          {timeComponent} {timezoneComponent}
        </span>
      ) : null}
      {/* Separate the time and date with a space */}
      {showTime && showDate ? " " : null}
      {showDate ? dateComponent : null}
    </time>
  );
};

export type DateTimeProps = {
  /**
   * The date time string to display.
   */
  dateTime?: string;
  /**
   * Fallback element to display in case data is not valid
   */
  fallback?: ReactNode;
  /**
   * Displays the date time in the user's timezone.
   */
  localize?: boolean;
  /**
   * Displays the date time in a stack layout.
   */
  stack?: boolean;
  /**
   * Removes default styling of the text.
   */
  plain?: boolean;
  /**
   * Show the date part of text. Default `true`.
   *
   * This and `showTime` cannot both be false.
   */
  showDate?: boolean;
  /**
   * Show the time part of text. Default `true`.
   *
   * This and `showDate` cannot both be false.
   */
  showTime?: boolean;
  /**
   * The base font size for the time.
   */
  size?: FontSize;
  /**
   * An object that will be used as the element styles for the root element.
   */
  style?: CSSProperties;
  /**
   * A string for defining one or more classes that will be added to the datetime's root element.
   */
  bold?: boolean;
  /**
   * A bool for setting all text to bold.
   */
  className?: string;
  /**
   * Pass in subcomponents to override default styles provided by DateTime.
   */
  children?: ReactNode;
};

/** Subcomponents **/

type TimeProps = SharedTextProps & { time?: ReactNode };

DateTime.Time = ({ time, ...textProps }: TimeProps) => {
  return (
    <Text data-qa="text-time" {...textProps}>
      {time}
    </Text>
  );
};

type TimezoneProps = SharedTextProps & { timezone?: ReactNode };

DateTime.Timezone = ({ timezone, ...textProps }: TimezoneProps) => {
  return (
    <Text data-qa="text-timezone" {...textProps}>
      {timezone}
    </Text>
  );
};

type DateProps = SharedTextProps & { date?: ReactNode };

DateTime.Date = ({ date, ...textProps }: DateProps) => {
  return (
    <Text data-qa="text-date" {...textProps}>
      {date}
    </Text>
  );
};

export { FontSize };
