import { createSelector } from "reselect";

import InventoryViewDomainDataState from "modules/domain-data/InventoryViewDomainData";
import { createOptionsMapper } from "utils/selectors-utils";

export const selectCurrentPositionTypes = createSelector(
  [InventoryViewDomainDataState.selectors.getCurrentPositionTypes],
  createOptionsMapper({
    labelKey: (item) => `${item.label}`,
    valueKey: (item) => `${item.value}`,
  }),
);

export const selectCarrierFilterOptions = createSelector(
  [InventoryViewDomainDataState.selectors.getCarriers],
  createOptionsMapper({
    labelKey: (item) => `${item.carrier_name} (${item.carrier_scac})`,
    valueKey: "carrier_id",
  }),
);
